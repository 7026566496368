






import Vue from 'vue';
import { Chart } from 'highcharts-vue';

import { ObjectiveLabel } from '../types/gitlab';

export default Vue.extend({
  name: 'ScatterGraph',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
    };
  },
  props: {
    series: Array,
  },
  methods: {
    getChartOptions() {
      return {
        chart: {
          zoomType: 'x',
          type: 'scatter',
          height: 200,
          scrollablePlotArea: {
            minWidth: 700,
          },
        },
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: '',
          },
          categories: [
            ObjectiveLabel.UNRESOLVED_INCIDENT,
            ObjectiveLabel.UNASSIGNED_CHANGE,
            ObjectiveLabel.UNSCHEDULED_CHANGE,
            ObjectiveLabel.PLANNED_CHANGE,
            ObjectiveLabel.DEPLOYMENT,
          ],
        },
        xAxis: {
          tickWidth: 0,
          gridLineWidth: 1,
          min: Date.UTC(this.currentYear - 1, this.currentMonth, 0),
          max: Date.UTC(this.currentYear, this.currentMonth + 1, 1),
          allowDecimals: false,
          type: 'datetime',
          tickInterval: 24 * 3600 * 1000 * 30, //one day
          currentDateIndicator: {
            width: 5,
            label: {
              format: '%d/%m/%Y, %H:%M',
            },
          },
          labels: {
            rotation: 30,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)',
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.title}' + '<br/><b>{point.x:%d/%m/%Y %H:%m}</b>' + '<br/>{point.size}',
            },
          },
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: Date.UTC(this.currentYear, this.currentMonth, 0),
            pointInterval: 24 * 3600 * 1000 * 30,
          },
        },
        series: this.series,
      };
    },
  },
});
