










































































































import { DateTime } from 'luxon';
import { QuotationEvent, QuotationStatus } from '../types/gitlab';
import QuotationEndPoint from '../services/quotationEndpoint';
import Service from '../services/quotation';
import Filters from '@/components/Filters.vue';
import _ from 'lodash';
import Vue from 'vue';
import Sub from '@/components/layout/Sub.vue';

export default Vue.extend({
  name: 'Facture',
  components: {
    Filters,
    Sub,
  },
  data() {
    return {
      date1: '',
      date2: '',
      date: '',
      dateFacture: new Date(),
      count: 0,
      total: 0,
      answer: null,
      selfTicketOnly: false,
      customerId: 'admin',
      timer: 0,
      quotation: null,
      state: undefined,
      selectedOptions: [
        { value: null, text: 'Tous' },
        { value: QuotationEvent.EMIT, text: 'Emis' },
        { value: QuotationEvent.REALISED, text: 'Réalisé' },
        { value: QuotationEvent.COUNTED, text: 'Comptabilisé' },
      ],
      actionOptions: [
        { value: QuotationStatus.BILL, text: 'A facturer' },
        { value: QuotationStatus.OFFER, text: 'Offert' },
        { value: QuotationStatus.NOTHING, text: 'Non avenu' },
      ],
      selectedAction: null,
      fields: [
        {
          key: 'counted_at',
          label: 'Statut',
          sortable: true,
          formatter: (value, key, row) => {
            if (row.counted_at != null) {
              return 'Comptabilisé';
            } else if (row.completed_at != null) {
              return 'Réalisé';
            } else {
              return 'Emis';
            }
          },
        },
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
          formatter: (value, key, row) => {
            let date = '';
            if (row?.customer) date += '<span class="badge badge-info">' + row.customer.id + '</span>';
            date +=
              '<span class="badge badge-dark">Créé le ' +
              DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT) +
              '</span> ';
            if (row.completed_at)
              date +=
                ' <span class="badge badge-dark">Réalisé le ' +
                DateTime.fromISO(row.completed_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span> ';
            /*
            if (row.counted_at)
              date +=
                ' <span class="badge badge-dark">Facturé le ' +
                DateTime.fromISO(row.counted_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span>';
            */
            return '<a href="' + row.reference_url + '" target="_blank">' + value + '</a>' + '<div>' + date + '</div>';
          },
        },
        {
          key: 'estimated',
          label: 'Charge',
          sortable: true,
          formatter: value => {
            return value + 'j';
          },
        },
        {
          key: 'counted_status',
          label: 'Etat',
          sortable: true,
          formatter: value => {
            switch (value) {
              case QuotationStatus.BILL:
                return 'A facturer';
              case QuotationStatus.OFFER:
                return 'Offert';
              case QuotationStatus.NOTHING:
                return 'Non avenu';
              default:
                return '-';
            }
          },
        },
        {
          key: 'counted_date',
          label: 'Mois',
          sortable: true,
          formatter: value => {
            return value ? DateTime.fromISO(value).toFormat('dd/LL/yy') : '-';
          },
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      calendarFilterFields: [
        {
          content: 'Tickets créés entre',
          value: 'begin',
        },
        {
          content: 'Tickets clôturés entre',
          value: 'resolved',
        },
        {
          content: 'Devis facturés entre ',
          value: 'counted',
        },
      ],
      data: [],
    };
  },
  watch: {
    quotation(to) {
      localStorage.quotation = to;
      this.getItems();
    },
    selfTicketOnly() {
      localStorage.selfTicketOnly = this.selfTicketOnly;
      const filterData = {
        date1: this.date1,
        date2: this.date2,
      };
      this.refreshData(filterData);
    },
    state(to) {
      localStorage.state = to;
      this.getItems();
    },
  },
  destroyed(): void {
    clearInterval(this.timer);
  },
  async mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      await this.$router.push('/Login');
    }
    if (localStorage.role == 'regular') {
      this.$router.push('/devis');
    }
    if (localStorage.state) {
      this.state = localStorage.state;
    }
    this.$root.$on('currentClient', data => {
      this.refreshData(data);
    });
    this.getItems();
    this.interval();
  },
  methods: {
    selectAction(row) {
      this.selectedAction = row;
    },
    interval() {
      if (this.timer > 0) {
        clearInterval(this.timer);
        this.timer = 0;
      }
      this.timer = window.setInterval(() => this.getItems(), 60000); //refresh 1 minute
    },
    async refreshData(a) {
      if (a.customerId) this.customerId = a.customerId;
      this.getItems();
    },
    camelCase(value) {
      return _.camelCase(value);
    },
    setData(a) {
      if (a.customerId) this.customerId = a.customerId;
      if (a.date1) this.date1 = a.date1;
      if (a.date2) this.date2 = a.date2;
      this.refreshData(a);
    },
    async getItems() {
      await Service.get(
        QuotationEndPoint.get('quotations', this.customerId) + QuotationEndPoint.params(this.quotation),
      ).then(response => {
        if (this.quotation == QuotationEvent.COUNTED)
          response = _.filter(response, function(o) {
            return o.counted_at;
          });

        if (this.quotation == QuotationEvent.REALISED)
          response = _.filter(response, function(o) {
            return o.completed_at && o.counted_at == null;
          });

        if (this.quotation == QuotationEvent.EMIT)
          response = _.filter(response, function(o) {
            return o.counted_at == null && o.completed_at == null;
          });

        const total = _.map(response, 'estimated');
        this.total = _.sum(total);

        this.data = response;
        this.count = _.size(this.data);
      });
    },
    async setAction() {
      const today = new Date();
      const data = {
        action: 'count',
        date: today,
        countedDate: this.dateFacture,
        countedBy: localStorage.email,
        countedStatus: this.state,
      };
      await Service.post(QuotationEndPoint.action(this.selectedAction, this.customerId), data).then(response => {
        this.answer = response;
        this.getItems();
      });
    },
    async cancelAction() {
      const data = {
        action: 'count',
        date: null,
        countedDate: null,
        countedBy: localStorage.email,
        countedStatus: QuotationStatus.NOTHING,
      };
      await Service.post(QuotationEndPoint.action(this.selectedAction, this.customerId), data).then(response => {
        this.answer = response;
        this.getItems();
      });
    },
  },
});
