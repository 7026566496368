<template>
  <div class="container-fluid">
    <Filtered v-on:refresh="refreshData($event)"></Filtered>
    <div class="container-fluid">
      <b-card>
        <div class="row m-0 p-0">
          <div class="col-1 d-flex justify-content-center align-items-center">
            <b v-if="count > 1">
              <b-badge variant="light">{{ count == 100 ? '+' : '' }} {{ count }} lignes</b-badge>
            </b>
            <b v-else>{{ count }} ligne</b>
          </div>
          <div class="col-3 d-flex justify-content-center align-items-center">
            <b-form-select v-model="kind" :options="selectedOptions"></b-form-select>
          </div>
          <div class="col-3 d-flex justify-content-center align-items-center">
            <b-form-radio-group v-model="state" :options="stateOptions"></b-form-radio-group>
          </div>
        </div>
      </b-card>
    </div>
    <div class="table row">
      <div class="col-12 m-auto">
        <BootstrapTable :columns="columns" :data="data" :options="options" data-pagination="true" />
      </div>
    </div>
    <div>
      <ScatterGraph :series="series"></ScatterGraph>
    </div>
  </div>
</template>

<script>
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm.js';
import { DateTime } from 'luxon';
import Duree from '../services/duree';
import { ObjectiveKind, ObjectiveText, ObjectiveState } from '../types/gitlab';
import EndPoint from '../services/endpoint';
import IncidentService from '../services/incident';
import Filtered from '@/components/Filter.vue';
import _ from 'lodash';
import ScatterGraph from '../components/ScatterGraph';
import Highchart from '../services/scatter';

export default {
  components: {
    BootstrapTable,
    Filtered,
    ScatterGraph,
  },
  data() {
    return {
      selectedKpi: '',
      count: 0,
      customerId: 'admin',
      isBusy: false,
      kind: undefined,
      state: undefined,
      selectedOptions: [
        { value: null, text: 'Tous les objectifs' },
        { value: ObjectiveKind.UNRESOLVED_INCIDENT, text: 'Incident' },
        { value: ObjectiveKind.UNASSIGNED_CHANGE, text: 'Qualification Travaux' },
        { value: ObjectiveKind.UNSCHEDULED_CHANGE, text: 'Réalisation Travaux' },
        { value: ObjectiveKind.PLANNED_CHANGE, text: 'Planification Travaux' },
        { value: ObjectiveKind.DEPLOYMENT, text: 'Déploiement' },
      ],
      stateOptions: [
        { value: null, text: 'Tout' },
        { value: ObjectiveState.OK, text: 'OK' },
        { value: ObjectiveState.KO, text: 'KO' },
      ],
      columns: [
        {
          title: 'Type',
          field: 'kind',
          sortable: 'true',
          formatter: value => {
            return ObjectiveText[value];
          },
        },
        {
          title: 'Assigné à',
          field: 'assignee',
          sortable: 'true',
          formatter: value => {
            return value ? value : 'Pas encore assigné';
          },
        },
        {
          field: 'title',
          title: 'Titre',
          sortable: 'true',
          formatter: (value, row) => {
            const subtitle = Duree.getSubtitle(value, 'key', row);
            return (
              '<a href="' + row.reference_url + '" target="_blank">' + value + '</a>' + '<div>' + subtitle + '</div>'
            );
          },
        },
        {
          field: 'begin_at',
          title: 'Dates',
          sortable: 'true',
          formatter: (value, row) => {
            let date = '';
            if (row?.customer) date += '<span class="badge badge-info">' + row.customer.id + '</span>';
            date +=
              '<div><span class="badge badge-dark">Ouvert le ' +
              DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT) +
              '</span></div>';
            if (row.resolved_at)
              date +=
                '<span class="badge badge-dark">Résolu le ' +
                DateTime.fromISO(row.resolved_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span>';
            if (row.cancelled_at)
              date +=
                '<span class="badge badge-dark">Annulé le ' +
                DateTime.fromISO(row.cancelled_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span>';

            return date;
          },
        },
        {
          field: 'action',
          title: '',
          sortable: false,
          cellStyle: (value, row) => {
            const classe = row.resolved_at
              ? row.resolved_at > row.must_end_at
                ? 'table-danger'
                : 'table-success'
              : row.cancelled_at
              ? 'table-warning'
              : DateTime.local().toFormat('yyyy-MM-dd HH:mm') >=
                DateTime.fromISO(row.must_end_at).toFormat('yyyy-MM-dd HH:mm')
              ? 'table-danger'
              : 'table-success';
            return {
              classes: classe + ' text-center',
            };
          },
          formatter: (value, row) => {
            let label = 'En cours';
            if (row.resolved_at != null) {
              if (row.resolved_at > row.must_end_at) {
                label = 'Terminé';
              } else {
                label = 'Terminé';
              }
            } else if (row.cancelled_at != null) {
              label = 'Annulé';
            }
            return '<span class="font-weight-bold" title="' + row.metadata.reference_url + '">' + label + '</span>';
          },
        },
      ],
      data: [],
      items: [],
      series: [],
      options: {
        search: true,
        showColumns: false,
        escape: true,
      },
    };
  },
  watch: {
    $route(to, from) {
      if (to.query?.q) {
        localStorage.selectedKpi = to.query.q;
      }
      this.getItems();
    },
    kind(to, from) {
      localStorage.kind = to;
      this.getItems();
    },
    state(to, from) {
      localStorage.state = to;
      this.getItems();
    },
    // react to route changes...
  },
  mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }
    if (localStorage.kind) {
      this.kind = localStorage.kind;
    }
    if (localStorage.state) {
      this.state = localStorage.state;
    }
    if (this.$route.query?.q) {
      localStorage.selectedKpi = this.$route.query.q;
    }
    if (localStorage.selectedKpi) {
      this.selectedKpi = localStorage.selectedKpi;
    }

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/search') return;
      this.refreshData(data);
    });

    this.getItems();
  },
  methods: {
    async refreshData(a) {
      if (a.customerId) this.customerId = a.customerId;
      if (a.selectedKpi) this.selectedKpi = a.selectedKpi;
      this.getItems();
    },
    async getItems() {
      await IncidentService.get(EndPoint.get(this.customerId) + EndPoint.params(this.kind, this.state)).then(
        response => {
          if (localStorage.selectedKpi == 'kpi') {
            this.data = _.filter(response, function(o) {
              return o.resolved_at || o.cancelled_at;
            });
          } else {
            this.data = response;
          }
          this.count = _.size(this.data);
          this.series = Highchart.format(this.data);
          console.log('serie', this.data, this.columns);
        },
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-body {
  padding: 10px;
}
</style>
