import { QuotationEvent, Role } from '../types/gitlab';
import { DateTime } from 'luxon';

export default {
  get(url, customerId) {
    if (customerId == Role.ADMIN) {
      return url;
    } else {
      return 'customers/' + customerId + '/' + url;
    }
  },
  action(url, customerId) {
    return 'customers/' + customerId + '/quotations/' + url;
  },
  params(kind = null, state = null) {
    const before =
      localStorage.fieldFilter == 'resolved'
        ? 'completedBefore'
        : localStorage.fieldFilter == 'begin'
        ? 'createdBefore'
        : 'countedBefore';
    const after =
      localStorage.fieldFilter == 'resolved'
        ? 'completedAfter'
        : localStorage.fieldFilter == 'begin'
        ? 'createdAfter'
        : 'countedAfter';

    let request = '';
    if (localStorage.date1 && localStorage.date1 != '' && localStorage.date1 != 'null') {
      request = `&${after}=${localStorage.date1}`;
    }
    if (localStorage.date2 && localStorage.date2 != '' && localStorage.date2 != 'null') {
      const date2 = DateTime.fromISO(localStorage.date2)
        .plus({ days: 1 })
        .toFormat('yyyy-MM-dd');

      request += `&${before}=${date2}`;
    }

    if (state && state !== null && state !== 'null') request = request + '&state=' + state;

    request =
      localStorage.selfTicketOnly == 'true' && localStorage.email
        ? request + '&countedBy=' + localStorage.email
        : request;

    if (kind == QuotationEvent.EMIT) {
      request += '&completed=false&counted=false';
    }

    if (request != '') return '?' + request;
    else return '';
  },
};
