import _ from 'lodash';
import { DateTime } from 'luxon';
import { ObjectiveKind, ObjectiveNumber } from '../types/gitlab';

export default {
  format(data, type = 'customer_id') {
    const category = _.uniq(this.extractCategories(data, type));
    return category.map(value => {
      return this.extractSeries(data, value, type);
    });
  },
  formatCounter(data, type = 'customer_id') {
    return this.counter(data, type);
  },
  counter(dataList, type) {
    let response = [];
    dataList.forEach(row => {
      response.push({
        date: DateTime.fromISO(row.begin_at).toFormat('yyyy-LL-dd'),
        type: row[type],
      });
    });
    response = _.orderBy(response, 'date', 'asc');
    const result = _(response)
      .groupBy('type')
      .map(function(item, itemId) {
        const obj = {};
        const response = [];
        const val = _.countBy(item, 'date');
        _.map(val, function(y, x) {
          return response.push({ x: Date.parse(x), y });
        });
        obj.data = response;
        obj.name = itemId;
        return obj;
      })
      .value();
    console.log(JSON.stringify(result, null, 2));
    return result;
  },
  extractSeries(dataList, category, type) {
    const response = [];
    dataList.forEach(element => {
      if (element[type] == category) {
        response.push({
          title: element.title,
          kind: element.kind,
          x: Date.parse(element.begin_at),
          y: ObjectiveNumber[element.kind],
          size: this.getDuree(element),
          color: this.getDuree(element) ? '#f1b0b7' : null,
        });
      }
    });
    return {
      name: category,
      data: response,
    };
  },
  extractCategories(dataList, categoryField) {
    return dataList.map(data => {
      return data[categoryField];
    });
  },
  getDuree(row) {
    if (row.resolved_at != null) {
      return DateTime.fromISO(row.resolved_at) > DateTime.fromISO(row.must_end_at);
    } else if (row.cancelled_at != null) {
      return DateTime.fromISO(row.cancelled_at) > DateTime.fromISO(row.must_end_at);
    } else {
      return DateTime.local() > DateTime.fromISO(row.must_end_at);
    }
  },
};
